/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import { videoData } from "../utils/data/videoData";
import { VideoInterface } from "../utils/interfaces/video.interface";
import contentStyles from "../styles/components/content.module.scss";
import { NavLink } from "react-router-dom";
import playImage from "../assets/play.svg";
import filter from "../utils/helpers/filter";
import useTrackComponentTime from "../utils/helpers/useTrackComponentTime";
import { PARAMS, PLAYLIST, TRACKING } from "../utils/constants/constants";
import { UserContext } from "../App";
import useParam from "../utils/helpers/useParam";
import { videoThumbnails } from "../utils/data/thumbnailsData";

interface IPlaylistProps {
  query: string;
  contentType:string;
}

const Playlist: React.FunctionComponent<IPlaylistProps> = ({ query, contentType }) => {
  const trackComponent = useTrackComponentTime(TRACKING.Playlist.name);
  const careflowEnabled = useParam().get(PARAMS.careflow) === "true";
  const originalVideos = videoData.filter(x => !!x.careflow === careflowEnabled || x.type === 'webinar');
  const [videos, setVideos] = useState<VideoInterface[]>(originalVideos);
  const thumbnails = videoThumbnails;
  const userId = useContext(UserContext);

  const videoList = videos
    .reduce((acc: any, video: any) => {
      if (video.type === contentType) {
        return [
          ...acc,
          { ...video, videoDate: new Date(video.date) },
        ];
      }
      return acc;
    }, [])
    .sort(
      (videoA: any, videoB: any) => videoB.videoDate.getTime() - videoA.videoDate.getTime());


  useEffect(() => {
    query && query.length > 0
      ? setVideos(filterVideos())
      : setVideos(originalVideos);
  }, [query]);

  useEffect(() => {
    trackComponent();
  }, []);

  const filterVideos = () => {
    return originalVideos.filter((video) =>
      filter([video.title, video.description, video.category], query)
    );
  };

  return videos.length !== 0 ? (
      <div className={contentStyles["c-tile"]}>
      {videos &&
        videos.length > 0 &&
        videoList.map((video:any, index: number) => {
          return (
            <NavLink
              key={index}
              to={`video/${video.id}?userId=${userId}&careflow=${careflowEnabled}`}
              state={[TRACKING.Navigation.fromHome, contentType]}
            >
              <div className={contentStyles["c-card"]}>
                <div className={contentStyles["c-card__top"]}>
                  <img
                    src={video.thumbnail ?? thumbnails[video.id]}
                    className={contentStyles["c-card__top-image"]}
                    alt=""
                  />
                  <div className={contentStyles["c-card__top-play"]}>
                    <img
                      className={contentStyles["c-card__top-play-image"]}
                      src={playImage}
                      alt="Play"
                    />
                  </div>
                  {/* New Badge */}
                  {/* <div className={contentStyles["c-card__top-new"]}>New</div> */}
                </div>
                <label className={contentStyles["c-card__category"]}>
                  {video.category}
                </label>
                <h2 className={contentStyles["c-card__title"]}>
                  {video.title}
                </h2>
                {(contentType !== "webinar") ? (
                  <div className={contentStyles["c-card__date"]}>1 month ago</div>
                ): (
                  <div className={contentStyles["c-card__date"]}>{video.date ? video.date : ""}</div>
                )
                }
              </div>
            </NavLink>
          );
        })}
    </div>
    
  ) : (
    <div className={contentStyles.contentCenter}>{PLAYLIST.noResultText}</div>
  );
};

export default Playlist;

import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { useContext } from "react";
import { UserContext } from "../../App";

export default function useTrackComponentTime(
  componentName: string,
  ...rest: any
) {
  const userId = useContext(UserContext);
  const appInsights = useAppInsightsContext();
  return useTrackMetric(appInsights, componentName, { userId, ...rest[0] });
}

/* eslint-disable react-hooks/exhaustive-deps */
// Global Styles
import "./styles/index.scss";

import { Routes } from "react-router-dom";
import { Outlet, Route } from "react-router";
import Home from "./pages/home";
import Video from "./pages/video";
import useTrackComponentTime from "./utils/helpers/useTrackComponentTime";
import { createContext, useEffect } from "react";
import { PARAMS, TRACKING } from "./utils/constants/constants";
import useTrackEvents from "./utils/helpers/useTrackEvent";
import { AIReactCustomEvent } from "@microsoft/applicationinsights-react-js/types/useTrackEvent";
import useParam from "./utils/helpers/useParam";

export const UserContext = createContext("");
export const TrackContext = createContext<undefined | AIReactCustomEvent<any>>(
  undefined
);

function App() {
  const userId = useParam().get(PARAMS.userId) ?? "";
  const trackComponent = useTrackComponentTime(TRACKING.App.name);
  const trackPlayedTime = useTrackEvents(TRACKING.Video.playedTimeEventName, 0);

  useEffect(() => {
    trackComponent();
  }, []);

  return (
    <>
      <UserContext.Provider value={userId}>
        <Routes>
          {userId && userId.length > 0 ? (
            <>
              <Route path="/*" element={<Home />}></Route>
              <Route path="video">
                <Route
                  path=":videoId"
                  element={
                    <TrackContext.Provider value={trackPlayedTime}>
                      <Video />
                    </TrackContext.Provider>
                  }
                ></Route>
              </Route>
            </>
          ) : (
            <Route index element={<Outlet />}></Route>
          )}
        </Routes>
      </UserContext.Provider>
    </>
  );
}

export default App;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ReactPlayer from "react-player";

interface IReactPlayerProps {
  [x: string]: any;
}

const VideoPlayer: React.FunctionComponent<IReactPlayerProps> = ({
  ...rest
}) => {
  return <ReactPlayer onError={(e)=> console.log(e)} {...rest} />;
};

export default VideoPlayer;

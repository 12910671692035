/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import headerStyles from "../styles/components/header.module.scss";
import searchImage from "../assets/search.svg";
import contentStyles from "../styles/components/content.module.scss";
import Reporting from "../components/reporting";
import Playlist from "../components/playlist";
import { PLAYLIST, TABNAMES, TABS } from "../utils/constants/constants";

const Home: React.FunctionComponent = () => {
  const [query, setQuery] = useState<string>("");
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [contentType, setContentType] = useState<string>("generic");
  const showVideo = selectedTabIndex === TABS.Playlist || selectedTabIndex === TABS.Webinars;

  const queryOnChange = (e: any) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <div className={headerStyles["c-header"]}>
        <div className={headerStyles["c-header__nav"]}>
          {TABNAMES.map((tab, index) => {
            return (
              <div
                key={index}
                className={`${headerStyles["c-tab-button"]} ${
                  selectedTabIndex === index && headerStyles["c-tab-button--active"]
                }`}
                onClick={() => { setSelectedTabIndex(index); setContentType(tab.contentType)}}
              >
                {tab.name}
              </div>
            );
          })}
        </div>
        {showVideo && (
          <input
            placeholder={PLAYLIST.searchPlaceHolder}
            type="text"
            className={`${headerStyles["c-search"]} ${headerStyles["c-search--lg"]}`}
            style={{ backgroundImage: `url(${searchImage})` }}
            value={query}
            onChange={queryOnChange}
          />
        )}
      </div>

      <div className={contentStyles["c-content"]}>
        {showVideo ? <Playlist query={query} contentType={contentType} /> : <Reporting />}
      </div>
    </>
  );
};

export default Home;

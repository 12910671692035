interface Props {
  text: string;
  highlight: string;
}

const HighLightText: React.FC<Props> = ({ text, highlight }) => {
  if (highlight.trim() === "") {
    return <>{text}</>;
  }
  // Split on highlight term and include term into parts, ignore case
  const regex = new RegExp(`(${highlight})`, "gi");
  const parts = text.split(regex);
  return (
    <>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <mark key={i}>{part}</mark>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
    </>
  );
};

export default HighLightText;

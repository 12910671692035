import { ReportInterface } from "../interfaces/report.interface";

export const reportData: ReportInterface[] = [
  {
    title: "Adjustments",
    description: {
      text: "View Production and Collection Adjustments for a specified date range.",
    },
    primaryUseCases: [
      {
        text: "Search Insurance and Patient Adjustment Types by patient count and dollar amount.",
      },
      {
        text: "Identify reasons for Production and/or Collection alterations to services after check out.",
      },
      {
        text: "Filter by Provider, Location, Adjustment Type/Amount, Patient/ Patient Flag, etc. for the previous month to run a summary/detailed view.",
      },
    ],
    similarReports: [
      { clientName: "Dentrix", text: "Adjustment Only Day Sheet" },
      { clientName: "Eaglesoft", text: "Daily Production/Collection Summary" },
      { clientName: "Open Dental", text: "Daily Adjustments Report" },
    ],
  },
  {
    title: "Aging (Enhanced)",
    description: {
      text: "View total outstanding balances from all patients and insurance. Track accounts receivable grouped by days aging in 0-30, 31-60, 61-90, 91-120, Over 120 as well as 180+. This report will show data up until yesterday’s date (T-1).",
    },
    primaryUseCases: [
      {
        text: "Track pending payable to the practice from patient/insurance and easily identify when patient statements should be sent or when to follow up with insurance carriers.",
      },
      {
        text: "Combine Total Outstanding, Insurance Unapplied Credits, and Patient Unapplied Credits of Patients with Outstanding to identify the Net A/R.",
      },
      {
        text: "Filter by Provider, Location, Adjustment Type/Amount, Patient/ Patient Flag, etc. to run a summary/detailed view.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Aging Report & Patient Balance Report & Insurance Aging Report",
      },
      { clientName: "Eaglesoft", text: "Accounts Receivable by RP" },
      {
        clientName: "Open Dental",
        text: "Aging of A/R Report & Insurance Aging Report",
      },
    ],
  },
  {
    title: "Appointments by Provider",
    description: {
      text: "Track all appointments in the status Scheduled, Checked out, Canceled or Other for a specified date range tagged to appointment providers at your practice.      ",
    },
    primaryUseCases: [
      {
        text: "View the total count of appointments and appointment details.      ",
      },
      { text: "Review scheduled production values for each provider." },
      {
        text: "Filter by Date, Provider, Production Type, Patient/Patient Flag, etc. to run a summary/detailed view.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Daily Appointment List",
      },
      {
        clientName: "Open Dental",
        text: "Daily Appointment List",
      },
    ],
  },
  {
    title: "Appointments by User",
    description: {
      text: "Track creation of appointments to evaluate number of appointments booked, scheduled production value, and average production scheduled by a specified user for a selected date range.",
    },
    primaryUseCases: [
      { text: "View the count of appointments and appointment details." },
      { text: "Review production for each provider." },
      {
        text: "Filter by Date, Production Type, Patient/Patient Flag, etc. to run a summary/detailed view.",
      },
    ],
    similarReports: [
      {
        clientName: "Open Dental",
        text: "List of Appointments by User (query example)",
      },
    ],
  },
  {
    title: "Care Notes Tracker",
    description: {
      text: "Track clinical Care Notes for codes or conditions that have been added to a patient’s chart including those that are unlinked.",
    },
    primaryUseCases: [
      {
        text: "Run this report End of Day(EOD) to check if the provider has missed out on adding Care Notes against the codes completed on that day.",
      },
      {
        text: "Track Care Notes by its status such as To be reviewed/completed/finalized, etc.",
      },
      {
        text: "Filter by treatment/Care Note location, Treatment/Appointment/Care Note Provider, etc.",
      },
    ],
    similarReports: [
      {
        clientName: "Open Dental",
        text: "Incomplete Procedure Notes Report",
      },
    ],
  },
  {
    title: "Clock In/Clock Out",
    description: {
      text: "Track user specific information on hours worked and wages based on those hours.",
    },
    primaryUseCases: [
      {
        text: "Provides the total number of hours the user has clocked in/out.",
      },
      {
        text: "Offers time specific pay details based on the hours the users have worked.",
      },
      { text: "Filter by user, location, etc." },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Employee Clock In/Out via QuickLaunch",
      },
      { clientName: "Eaglesoft", text: "Employee Time Sheet" },
      {
        clientName: "Open Dental",
        text: "Time Card",
      },
    ],
  },
  {
    title: "Claims",
    description: {
      text: "View claims that have been generated by the practice such as the expected Insurance amount from each Carrier by days aging in 0-30, 31-60, 61-90, 91-120, and Over 120 to follow up on outstanding insurance A/R with the carrier. The data includes Claims sent out, the Claim Status, Outstanding Balance, all patient information requested by the carrier and so forth",
    },
    primaryUseCases: [
      {
        text: "Identify claims that require attention as well those that have been rejected.",
      },
      {
        text: "Follow up on claims that have been sent out but not yet acknowledged by the Carrier.",
      },
      {
        text: "Filter by Carrier, Plan Type, Claim Flag/Status, Provider/Location, etc.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Insurance Aging Report",
      },
      { clientName: "Eaglesoft", text: "Outstanding Claims Report" },
      {
        clientName: "Open Dental",
        text: "Outstanding Insurance Claims Report",
      },
    ],
  },
  {
    title: "Credit Balance",
    description: {
      text: "View all Patient Unapplied Credits from receipts (patient payments) collected by the practice. Identify credits that need to be allocated toward open balances on procedure codes or overpayments that may need to be refunded to the patient.",
    },
    primaryUseCases: [
      { text: "Identify all patients with remaining credit amounts." },
      {
        text: "Generate a working list for immediate action to apply credits by entering a dollar value >$0 to the filters for individual outstanding and >$0 for Pat. Unapplied.",
      },
      {
        text: "Enter =$0 to the filter for individual outstanding and >$0 for Pat. Unapplied to generate a working list to identify patient overpayments that require a refund to the patient.",
      },
      {
        text: "Filter by day, aging bucket, location, etc. to run a summary/detailed view.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Aging Report",
      },
      {
        clientName: "Eaglesoft",
        text: "Accounts Receivable by RP (Filter out + Balances)",
      },
      {
        clientName: "Open Dental",
        text: "Aging of A/R report",
      },
    ],
  },
  {
    title: "Daily Journal",
    description: {
      text: "Retrieve a summary or full detailed analysis of the daily operations and how they contribute to the practice’s Daily Gross Production, Net Production, Gross Collection, Net Collection, production and collection adjustments by Transaction Date or DOS.",
    },
    primaryUseCases: [
      { text: "Compare the codes that have been completed for the day." },
      { text: "Review receipts that have been added for the day." },
      {
        text: "Filter by Provider/Location to run a summary/detailed view with the ability to exclude membership discount credits.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Day sheet",
      },
      {
        clientName: "Eaglesoft",
        text: "Day sheet",
      },
      {
        clientName: "Open Dental",
        text: "Daily Production and Income Report or Custom Daysheet Report (query example)",
      },
    ],
  },
  {
    title: "Deposit Slip",
    description: {
      text: "Track all receipts that contribute to Gross Collection to match what has been deposited into the bank account. Use this report to reconcile the total money collected at your practice via Payment categories such as Cash, Care Credit, Debit/Credit Card, and Direct Transfer at your practice. Exclusions: Special Credits, Transfer Receipts, Income Reduction, Membership credits & Refunds.",
    },
    primaryUseCases: [
      {
        text: "By default, cash and check are selected to identify what should be physically deposited into the bank.",
      },
      {
        text: "Run it EOD to verify total Cash/Check deposited to the practice’s bank account. Select all Payment Categories in this report to match daily Gross Collection to the bank account.",
      },
      {
        text: "Determine how much money has been deposited in the banks by location.",
      },
      {
        text: "Filter by Payment Type/Category, Patient, Carrier, Collection Agency, etc.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Day sheet > Deposit Slip",
      },
      {
        clientName: "Eaglesoft",
        text: "Deposit Slip",
      },
      {
        clientName: "Open Dental",
        text: "Deposit Slip",
      },
    ],
  },
  {
    title: "Income Allocation",
    description: {
      text: "Provides location and provider transactional performance overview for production and applied collection for a selected date range. Also you can review total UCR, Gross Production, and Net Production. Additionally, it offers the ability to view provider payment allocation details by paying entity and payment categories.",
    },
    primaryUseCases: [
      {
        text: "View Opening/Closing balances, Gross Production, Production Adjustments, Net Production, Advanced Payments, Applied Payments, Collection Adjustments, and Net Applied Payments to a provider and/or location.",
      },
      {
        text: "Establish Provider Payroll and rely on transactional data to correct any underpayment and/or overpayments whether you pay providers on production or applied payments.",
      },
      {
        text: "Pull up a report based on Transaction Date to track provider performance",
      },
      {
        text: "Filter by Location, Provider Type, Provider, Patient Flag for a detailed focus on completed services, Production and Collection Adjustments and Net Applied Payments, etc.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Analysis Summary",
      },
      {
        clientName: "Eaglesoft",
        text: "Collection by Provider/Credit Distribution",
      },
      {
        clientName: "Dolphin",
        text: "Practice Analysis",
      },
    ],
  },
  {
    title: "Insurance Pending Procedures",
    description: {
      text: "Evaluate completed services that may require action to their billing status in order to properly submit services to the insurance carrier for the following 3 conditions:",
      bulletList: [
        "Completed Services Not Billed to Insurance",
        "Completed Services with Pending Primary Claim Creation",
        "Completed Services with Insurance mismatch",
      ],
    },
    primaryUseCases: [
      {
        text: "Identify patients with active insurance who have completed procedures in the billing status ‘Do Not Bill to Insurance’ that have a default billing order of ‘Bill to Insurance’ to ensure all billable codes are in the appropriate status to get picked up in the claims batch generation.",
      },
      {
        text: "Ensure claims are created within the timely filing limit by reviewing a list of completed procedures (DOS) that have a billing status ‘Pri. Claim not Created’. *If the claim is not created - it can’t be received by the carrier. Batch claims with no start date to generate claims for codes with billing order D for previous dates of service.",
      },
      {
        text: "Filter by Insurance Plan, Code, Carrier, Patient/Patient Flag, etc. to run a summary/detailed view.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Procedures not attached to Insurance & Primary Insurance not created & Secondary Insurance not created & Insurance Claims to process.",
      },
      {
        clientName: "Open Dental",
        text: "Claims Not Sent Report & Procedures Not Billed",
      },
    ],
  },
  {
    title: "Insurance Plans",
    description: {
      text: "Identify all insurance plans with the total number of patients that are actively covered under each plan.",
    },
    primaryUseCases: [
      {
        text: "View all patients or insurance holders entered into the system with a specific insurance plan.",
      },
      { text: "Run a custom report by Plan or Patient View." },
      { text: "Filter by Plan Type, Carrier, Employer, etc." },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Insurance Carrier List",
      },
      {
        clientName: "Eaglesoft",
        text: "Patients by Insurance Plan",
      },
      {
        clientName: "Open Dental",
        text: "Insurance Plans List",
      },
    ],
  },
  {
    title: "Lab Case",
    description: {
      text: "Display lab cases that are linked to an appointment.",
    },
    primaryUseCases: [
      { text: "Identify patients who have pending Lab Cases" },
      { text: "Find Lab Cases that are due or overdue." },
      { text: "Filter by Lab, Patient/Patient Flag, Provider, etc." },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Lab Case Manager",
      },
      {
        clientName: "Eaglesoft",
        text: "Lab Tracker",
      },
      {
        clientName: "Open Dental",
        text: "Lab Cases List",
      },
    ],
  },
  {
    title: "Missing Transactions",
    description: {
      text: "Discover Procedure Codes that were missed during the checkout process.",
    },
    primaryUseCases: [
      {
        text: "View all past appointments with at least one non-completed code.",
      },
      {
        text: "Look up checked-out appointments with no codes associated in the selected Date Range.",
      },
      {
        text: "Filter by Appointment Status, Production Type, Provider, Patient/Patient Flag, etc.",
      },
    ],
    similarReports: [
      {
        clientName: "Dolphin",
        text: "Treatment Card Charges Not Posted to Ledgers",
      },
    ],
  },
  {
    title: "New Patients",
    description: {
      text: "Track new patients that have visited the practice and identify if they have a future appointment.",
    },
    primaryUseCases: [
      {
        text: "View total number of new patients and the production contributed by them at each provider/location.",
      },
      {
        text: "Track new patients and their scheduled production for an upcoming appointment.",
      },
      {
        text: "Select summary view to look up first visit production value, total production, as well as production of future appointments scheduled for new patients.",
      },
      {
        text: "Choose a detailed view to obtain patient specific details such as location, provider, referral source, first date/last date of visit as well as next appt. date/status/scheduled production/production type.",
      },
      {
        text: "Filter by Patient/Patient Flag, Provider, etc. to run a summary/detailed view.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "New Patient List",
      },
      {
        clientName: "Eaglesoft",
        text: "New Persons Report",
      },
      {
        clientName: "Open Dental",
        text: "New Patients Report",
      },
    ],
  },
  {
    title: "Patient Time Tracker",
    description: {
      text: "Evaluate the amount of time the appointment has spent in each specified status.",
    },
    primaryUseCases: [
      {
        text: "Estimate the amount of time the patient has spent in the waiting room or operatory.",
      },
      { text: "Track task productivity-based appointment status." },
      {
        text: "Filter by Provider, Location, Appointment Status to run a summary/detailed view.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Patient Arrival Tracker",
      },
      {
        clientName: "Open Dental",
        text: "Waiting Room Report (query example)",
      },
    ],
  },
  {
    title: "Payment Log",
    description: {
      text: "Lists out the day’s payment transactions and revenue performance.",
    },
    primaryUseCases: [
      { text: "Run it EOD (End of Day) to review the day’s payment balances." },
      {
        text: "Retrieve data based on Transaction Date, Payment Date, or Deposit Date.",
      },
      {
        text: "Filter by User, Paying Entity, Receipt Location, etc. to run a summary/transaction/receipt or collection view.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Day sheet - Receipts only",
      },
      {
        clientName: "Eaglesoft",
        text: "Collection Reconciliation",
      },
    ],
  },
  {
    title: "Payment Reconciliation",
    description: {
      text: "Enables reconciliation of all payment receipts added to the practice.",
    },
    primaryUseCases: [
      {
        text: "View receipts added at the selected locations from Patient Insurance, Collection Agencies & Deletion of Receipts.",
      },
      {
        text: "Look up applied Payments within the selected Date Range as well as check Adjustment Transactions, Reversals, Refunds, Adjust Off’s, & Transfers.",
      },
      {
        text: "Filter by Transaction Location/User, Adjustment Code, Receipt User, Payment Type etc.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Day sheet - Receipts only",
      },
      {
        clientName: "Eaglesoft",
        text: "Collection Reconciliation",
      },
    ],
  },
  {
    title: "Pending Eligibility/Verification",
    description: {
      text: "View patients with upcoming appointments whose Insurance Plan or Eligibility needs to be verified.",
    },
    primaryUseCases: [
      {
        text: "Review upcoming Appointments with Patient’s Insurance that is either in Draft or Eligibility Pending Status. ",
      },
      {
        text: "Filter by Days since Last Eligibility, Insurance Type, Carrier, Patient/Patient Flag etc.",
      },
    ],
    similarReports: [
      {
        clientName: "Open Dental",
        text: "Insurance Verification List",
      },
    ],
  },
  {
    title: "Pre-Authorization",
    description: {
      text: "Track the status of all Pre-Authorizations created in the system.",
    },
    primaryUseCases: [
      { text: "Confirm whether the Pre-Authorization has been sent." },
      {
        text: "Monitor the status and schedule patients for whom the Pre-Authorizations have been approved.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Preauthorizations to Process & Preauthorization Aging Report",
      },
      {
        clientName: "Eaglesoft",
        text: "Outstanding Claims Report",
      },
      {
        clientName: "Open Dental",
        text: "Outstanding Insurance Claims Report-Filter by Pre-Auth.",
      },
    ],
  },
  {
    title: "Procedures",
    description: {
      text: "Enable users to view Procedure Code Statuses, and its related details",
    },
    primaryUseCases: [
      {
        text: "Lists out Unscheduled/Scheduled Codes, Fee Details associated with the Code, etc.",
      },
      {
        text: "Display list of Codes that were changed to a Code Status for the selected time range.",
      },
      {
        text: "Filter by Provider, Provider Type, Code, Patient/Patient Flag, etc. to run a summary/detailed view.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Day sheet",
      },
      {
        clientName: "Eaglesoft",
        text: "Procedures by Provider",
      },
      {
        clientName: "Open Dental",
        text: "Procedures Report",
      },
      {
        clientName: "Dolphin",
        text: "Dental Treatment Proposal, Dental Treatment Proposal with Signature.",
      },
    ],
  },
  {
    title: "Production Summary",
    description: {
      text: "View each provider’s Gross Production completed at each location by Transaction date or DOS.",
    },
    primaryUseCases: [
      { text: "View Gross Production grouped by Provider or Location." },
      {
        text: "Compare each Provider’s Gross Production against the total number of Completed Procedures for each location.",
      },
      {
        text: "View Production Summary detailing Provider, Gross production, Completed Procedures, Patients Seen, No. of appointments, Avg. Prod. per Code/Patient/Appt. & % of Location/Total Production.",
      },
      {
        text: "Filter by Treatment Location/Provider, Provider Type, etc. to run a summary/detailed view.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Day sheet",
      },
      {
        clientName: "Eaglesoft",
        text: "Provider Productivity Report",
      },
      {
        clientName: "Dolphin",
        text: "Provider Collection of Production by Employee, Dental Production by Employee",
      },
    ],
  },
  {
    title: "Refer In",
    description: {
      text: "Track the statistics on Referral Sources as well the Production generated from these sources.",
    },
    primaryUseCases: [
      {
        text: "Identify the receivables collected from Referral Patients whose accounts were created in the system.",
      },
      {
        text: "Look up receivables from Referral Patients who had their first Date of Service within the selected timeframe.",
      },
      {
        text: "Filter by Referral Source/Provider/Patient, Specialty, Referred Patient Flag, etc.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Referred By",
      },
      {
        clientName: "Eaglesoft",
        text: "Patients by Referral Source",
      },
      {
        clientName: "Open Dental",
        text: "Referral Analysis",
      },
    ],
  },
  {
    title: "Refer Out",
    description: {
      text: "Track the patients at your practice who were referred out to a specialty practice.",
    },
    primaryUseCases: [
      {
        text: "View all the referred out patients within the selected time frame.",
      },
      { text: "Ability to view the report in a single view for each patient." },
      {
        text: "Filter by Referred by/to, Referred to Specialty, Referral Provider, Patient etc.",
      },
    ],
    similarReports: [
      {
        clientName: "Dentrix",
        text: "Referred To",
      },
      {
        clientName: "Eaglesoft",
        text: "Patients currently Referred Out",
      },
      {
        clientName: "Open Dental",
        text: "Referred Procedure Tracking Report",
      },
    ],
  },
  {
    title: "Scheduled vs.Actual Production",
    description: {
      text: "Track the ‘Scheduled Production’ compared to your ‘Actual Production’ ((Actual Production = Gross Production (DOS)) along with the percentage of the Scheduled Production converted into Actual Production to analyze scheduling/production trends at your practice.",
    },
    primaryUseCases: [
      {
        text: "Find any remaining codes that were scheduled but not yet completed.",
      },
      {
        text: "Ensure procedure codes are linked to patient appointments prior to the day of to successfully track same-day-treatment values.",
      },
      {
        text: "Evaluate SOPs for appointment confirmations and/or broken appointments.",
      },
      {
        text: "Identify areas of opportunity i.e. if a column or provider is being underutilized, you can identify those and schedule more for that provider.",
      },
      { text: "Filter by Location/Provider for the selected date range." },
    ],
    similarReports: [
      {
        clientName: "Eaglesoft",
        text: "Month at a Glance",
      },
    ],
  },
  {
    title: "Utilization",
    description: {
      text: "Draw up data to monitor the frequency of diagnosis relative to peers or industry standards. This report is based on the Date of Service. Use it to evaluate the difference between your UCR fees compared to your contracted fees.",
    },
    primaryUseCases: [
      {
        text: "Shows information that enables practices in negotiating fees for insurance contracts as well as average fee/reimbursement rates.",
      },
      {
        text: "Filter by Tx Location/Tx Provider/Tx Provider Type, Primary Insurance Carrier, Code, etc.",
      },
    ],
    similarReports: [
      {
        clientName: "Eaglesoft",
        text: "Procedures by Provider",
      },
    ],
  },
  {
    title: "Walkout Balance",
    description: {
      text: "This report is run daily in order to measure what is being allowed to walk out the door in regard to patient financial responsibility.",
    },
    primaryUseCases: [
      {
        text: "View how much the patient owes the practice by End of Day (EOD).",
      },
      { text: "Filter by location to run a summary/detail view." },
    ],
    similarReports: [
      {
        clientName: "Eaglesoft",
        text: "Day Sheet",
      },
    ],
  },
  {
    title: "EOD Report",
    description: {
      text: "Evaluate the details of all transactions that impact your daily operations (production and collection) specific to one location, one day at a time. Transaction date is retrieved only for reconciliation purposes. Use this report for:",
    },
    primaryUseCases: [
      {
        text: "Track essential KPIs each day in the ‘Performance Summary’ to understand how each transaction contributes to your ending AR as well as meet organization goals.",
      },
      {
        text: "View Existing and New Patients who had an appointment checked out or had a treatment completed as well as Average production & visits from completed procedures.",
      },
      {
        text: "Provides a performance summary on Opening & Ending AR, Net Production/ Collection by Trans.Date besides percentage of Over the Counter payments as well as Patient/Insurance Collection Rate:",
        bulletList: [
          "Performance Summary",
          "Opening AR",
          "Ending AR",
          "Net Production (Trans. Date)",
          "Net Collection (Trans. Date)",
          "OTC Rate",
          "Pat Collection Rate",
          "Ins Collection Rate",
          "Patients Seen",
          "New Patients Seen",
          "Avg Prod/Visit",
        ],
      },
    ],
    similarReports: [
      {
        clientName: "Eaglesoft",
        text: "Day Sheet",
      },
    ],
  },
];

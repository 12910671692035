interface VideoThumbnails {
  [id: number]: string;
}

export const videoThumbnails: VideoThumbnails = {
  70: "https://i.vimeocdn.com/video/1868938785-6105e3470a748c57ae3bebc1e0a3b0ecbb4f8dabd1a388eae115831f226241de-d_295x166",
  71: "https://i.vimeocdn.com/video/1887159386-1066c632f468b8b946128f2ad2b3cf5b6c4ab79da41492e61f53bacbdfdaf01c-d_295x166",
  72: "https://i.vimeocdn.com/video/1887158915-904cd1a5b20e6547865e51a13f7756f67acdb6f189849a6eb4e1c044132899b4-d_295x166",
  73: "https://i.vimeocdn.com/video/1887159227-ae8b35b7548df9a6aa69c08d1781eb5261ea5eeafb204ba38264c7705c23fb0c-d_295x166",
  74: "https://i.vimeocdn.com/video/1887159460-01409b3e2d43c0ddb51f6dcc5f758505d5410a48bf8edfacbb63be6113b02092-d_295x166",
  75: "https://i.vimeocdn.com/video/1887159589-345084e5fce3dfc530461342f1a1b2fd4d0d2cd772c0aeb6282c932c23713050-d_295x166",
  76: "https://i.vimeocdn.com/video/1887159078-4e7af1e097789be26202bf0dec02763bf12f95010f750c546d76017535882588-d_295x166",
  77: "https://i.vimeocdn.com/video/1865051409-5b861d2970bbdaf808bd5900ac701c44d07934ac0ab14f1531db95300c9d84ce-d_295x166",
  78: "https://i.vimeocdn.com/video/1855669949-200cf6387f682c6bcb8190711d496dba28a3d6f5c58dac21777829721083ebc9-d_295x166",
  79: "https://i.vimeocdn.com/video/1855671544-11f4c4c647d9c0b65855a6d4b57ee5db3307d8d01258619e736eece424d9872a-d_295x166",
  80: "https://i.vimeocdn.com/video/1855671579-f546aee2962d3842d80d3faa9bc6428ed174661899e764854b509604a248016c-d_295x166",
  81: "https://i.vimeocdn.com/video/1856715799-df6225fdc21429c63ace2ca80b35b55e05f5a2f893298b9a317bccba816b9fe2-d_295x166",
  82: "https://i.vimeocdn.com/video/1855672187-a45b643842e3ef8ae877277f74e56caf393deae9e1586e1acd78203636f46b6c-d_295x166",
  83: "https://i.vimeocdn.com/video/1855672458-973ec72a8bff1b42401feacf0f4c43567313d7b7253b48537425d882e0522ddb-d_295x166",
  84: "https://i.vimeocdn.com/video/1865092404-adef44f72a55e5086c82e95f663af267262909348df9e500429e92ed5106d809-d_295x166",
  85: "https://i.vimeocdn.com/video/1865092404-adef44f72a55e5086c82e95f663af267262909348df9e500429e92ed5106d809-d_295x166",
  86: "https://i.vimeocdn.com/video/1855670658-992edc68013533dcb1094544d95a419811caa0fbe78d47bef57f62081c3e9b32-d_295x166",
  87: "https://i.vimeocdn.com/video/1855670881-d245674fead8a04f7760ea6ef75690f9817ff8626badc09d782072cbb240aad7-d_295x166",
  88: "https://i.vimeocdn.com/video/1905431207-d2236e004e87415cbc8a9815032f259ef543a11a373a471afabd9f05d800ac08-d_295x166",
  89: "https://i.vimeocdn.com/video/1874899637-46407564da480e2bcc39f1820e864645a6e0a0e768586d2ef396268cb860a877-d_295x166",
  90: "https://i.vimeocdn.com/video/1874892326-4e949bda753c4ca56c6139a30fbf56e1355030faca960e139347c80e29582029-d_295x166",
  91: "https://i.vimeocdn.com/video/1915144402-99dab1ac70e22e32b7ae696bcbd4a676c44442ab4b7d6c70ae2cd21f8a273471-d_295x166"
};

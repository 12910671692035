export default function filter(searchIndexes: string[], searchParams: string) {
  return searchIndexes
    .filter((index) => index)
    .some((index) =>
      searchParams
        .trim()
        .split(" ")
        .some((param) => index.toLowerCase().includes(param.toLowerCase()))
    );
}

import { VideoInterface } from "../interfaces/video.interface";
import Reserve from "../../assets/thumbnail/QSThumb-Reserve.png";
import CancelAppt from "../../assets/thumbnail/QSThumb-CancelAppt.png";
import AddPlanTx from "../../assets/thumbnail/QSThumb-AddPlanTx.png";
import AddScratch from "../../assets/thumbnail/QSThumb-AddScratch.png";
import PatProfile from "../../assets/thumbnail/QSThumb-PatProfile.png";
import PortalForm from "../../assets/thumbnail/QSThumb-PortalForm.png";
import SecondaryIns from "../../assets/thumbnail/QSThumb-SecondaryIns.png";
import PmtSlider from "../../assets/thumbnail/QSTHumb-PmtSlider.png";
import Chart from "../../assets/thumbnail/QSThumb-Chart.png";
import Note from "../../assets/thumbnail/QSThumb-Note.png";
import Prescription from "../../assets/thumbnail/QSThumb-Prescription.png";
import Perio from "../../assets/thumbnail/QSThumb-Perio.png";
import TXPlan from "../../assets/thumbnail/QSThumb-TXPlan.png";
import CaseDeliver from "../../assets/thumbnail/QSThumb-CaseDeliver.png";
import SummaryDoc from "../../assets/thumbnail/QSThumb-SummaryDoc.png";
import ClaimSliderForm from "../../assets/thumbnail/QSThumb-ClaimSlider-Form.png";
import ClaimModule from "../../assets/thumbnail/QSThumb-ClaimModule.png";
import InsPmtModule from "../../assets/thumbnail/QSThumb-InsPmtModule.png";
import AddInsurance from "../../assets/thumbnail/QSThumb-AddInsurance.png";
import StatusChange from "../../assets/thumbnail/QSThumb-StatusChange.png";
import Bootcamp1 from "../../assets/thumbnail/BTThumb1.png";
import Bootcamp2 from "../../assets/thumbnail/BTThumb2.png";
import Bootcamp3 from "../../assets/thumbnail/BTThumb3.png";
import Bootcamp4 from "../../assets/thumbnail/BTThumb4.png";
import Bootcamp5 from "../../assets/thumbnail/BTThumb5.png";
import Bootcamp6 from "../../assets/thumbnail/BTThumb6.png";
import Bootcamp7 from "../../assets/thumbnail/BTThumb7.png";
import Bootcamp8 from "../../assets/thumbnail/BTThumb8.png";
import Bootcamp9 from "../../assets/thumbnail/BTThumb9.png";
import Bootcamp10 from "../../assets/thumbnail/BTThumb10.png";
import Bootcamp11 from "../../assets/thumbnail/BTThumb11.png";
import Bootcamp12 from "../../assets/thumbnail/BTThumb12.png";
import Bootcamp13 from "../../assets/thumbnail/BTThumb13.png";
import Bootcamp14 from "../../assets/thumbnail/BTThumb14.png";
import Bootcamp15 from "../../assets/thumbnail/BTThumb15.png";
import Bootcamp16 from "../../assets/thumbnail/BTThumb16.png";
import Bootcamp17 from "../../assets/thumbnail/BTThumb17.png";
import Bootcamp18 from "../../assets/thumbnail/BTThumb18.png";
import Bootcamp19 from "../../assets/thumbnail/BTThumb19.png";
import Bootcamp21 from "../../assets/thumbnail/BTThumb21.png";
import Bootcamp22 from "../../assets/thumbnail/BTThumb22.png";
import Bootcamp23 from "../../assets/thumbnail/BTThumb23.png";
import Bootcamp24 from "../../assets/thumbnail/BTThumb24.png";
import Bootcamp25 from "../../assets/thumbnail/BTThumb25.png";
import Bootcamp26 from "../../assets/thumbnail/BTThumb26.png";
import Bootcamp27 from "../../assets/thumbnail/BTThumb27.png";
import Bootcamp28 from "../../assets/thumbnail/BTThumb28.png";
import Bootcamp29 from "../../assets/thumbnail/BTThumb29.png";
import Webinar533 from "../../assets/thumbnail/WebinarThumb-533.png";
import Bootcamp30 from "../../assets/thumbnail/BTThumb30.png";
import Bootcamp31 from "../../assets/thumbnail/BTThumb31.png";
import Bootcamp32 from "../../assets/thumbnail/BTThumb32.png";
import Bootcamp33 from "../../assets/thumbnail/BTThumb33.png";
import Bootcamp34 from "../../assets/thumbnail/BTThumb34.png";
import Webinar535 from "../../assets/thumbnail/WebinarThumb-535.png";
import Webinar536 from "../../assets/thumbnail/WebinarThumb-536.png";
import Bootcamp35 from "../../assets/thumbnail/BTThumb35.png";
import Bootcamp36 from "../../assets/thumbnail/BTThumb36.png";
import CareFlow from "../../assets/thumbnail/careflowThumb1.png";

export const videoData: VideoInterface[] = [
  {
    id: 1,
    title: "How to reserve an appointment slot",
    link: "https://vimeo.com/667390897",
    description:
      "Reserving the slot is the first step toward booking the appointment. The slot you select establishes four key pieces of your appointment: Location, Date & Time, Duration, and Chair.  Learn to grab your spot and get your appointment set.",
    type: "generic",
    category: "Book an Appointment",
    thumbnail: Reserve,
  },
  {
    id: 2,
    title: "How to add treatment from the patient's plan",
    link: "https://vimeo.com/667392883",
    description:
      "Link treatments  into your appointment so both your team and the patients will know what to expect in the appointment.   Learn to include the treatment plan, phase, or individual code from the patient's plan.",
    type: "generic",
    category: "Book an Appointment",
    thumbnail: AddPlanTx,
  },
  {
    id: 3,
    title: "How to add unplanned treatment",
    link: "https://vimeo.com/667393555",
    description:
      "Link treatments  into your appointment so both your team and the patients will know what to expect in the appointment.  Learn to add the anticipated treatments based on your patient's needs.",
    type: "generic",
    category: "Book an Appointment",
    thumbnail: AddScratch,
  },
  {
    id: 4,
    title: "How can I cancel an appointment",
    link: "https://vimeo.com/765882388",
    description:
      "If the patient cannot make their scheduled appointment, you'll need to cancel it.  When the patient is ready to schedule, grab all the details from the Rescheule Queue.  Learn to follow this process and keep your appointment process smooth",
    type: "generic",
    category: "Cancelling and Rescheduling",
    thumbnail: CancelAppt,
  },
  {
    id: 5,
    title: "How to create a patient profile",
    link: "https://vimeo.com/457830710",
    description:
      "The dynamic patient profile connects you to everything you need to know about the patient.  Learn to gather the key details to build the core of the patient record.",
    type: "generic",
    category: "Patient Profiles",
    thumbnail: PatProfile,
  },
  {
    id: 6,
    title: "How to add an electronic form",
    link: "https://vimeo.com/764586217",
    description:
      "Gather the information you need from your patients with your online forms so the patient can complete them in the Patient Portal or integrated kiosk.  Learn to add the forms to the profile.",
    type: "generic",
    category: "Patient Documents",
    thumbnail: PortalForm,
  },
  {
    id: 7,
    title: "How to attach insurance to the profile",
    link: "https://vimeo.com/446706823",
    description:
      "The patient's insurance plan is crucial to knowing their costs for the treatment.  Learn to connect the insurance to the record with some or all of the information and keep the information handy.",
    type: "generic",
    category: "Attach Insurance",
    thumbnail: AddInsurance
  },
  {
    id: 8,
    title: "How to attach secondary insurance.",
    link: "https://vimeo.com/576247752",
    description:
      "When a patient has more than one insurance plan, you need to account for the differences in benefits.  Learn to manage the hierarcy of multiple insurance plans.",
    type: "generic",
    category: "Secondary Insurance",
    thumbnail: SecondaryIns,
  },
  {
    id: 9,
    title: "How to change an appointment status",
    link: "https://vimeo.com/672878054",
    description:
      "Appointment status is an easy way to categorize an appointment.  Learn to use appointment status to track your patient's confirmation and where they are in their appointment flow.",
    type: "generic",
    category: "Working With Appointments",
    thumbnail: StatusChange
  },
  {
    id: 10,
    title: "How to check eligibility benefits",
    link: "https://vimeo.com/459539588",
    description:
      "Eligiblity details are crucial to knowing if a patient's treatment will be covered and by how much.  Learn to add the information from the carriers into CareStack or precise calculations.",
    type: "generic",
    category: "Insurance Eligbility",
    thumbnail: AddInsurance,
  },
  {
    id: 11,
    title: "How to take a payment before the treatment",
    link: "https://vimeo.com/489934589",
    description:
      "CareStack ties payments to individual codes, but if the patient hasn't had the appointment, there aren't any codes.  Learn to enter a receipt for a patient before the treatment so you can apply the funds later.",
    type: "generic",
    category: "Unapplied Credits and Advance Payments",
    thumbnail: PmtSlider,
  },
  {
    id: 12,
    title: "How to add items to a chart",
    link: "https://vimeo.com/619568208",
    description:
      "The chart describes a patient's oral health with a complete record of conditions, existing work, and planned treatments.  Learn to add these key elements into the chart.",
    type: "generic",
    category: "Build a Chart",
    thumbnail: Chart,
  },
  {
    id: 13,
    title: "How to complete items in the chart",
    link: "https://vimeo.com/641315698",
    description:
      "Completing a treatment clarifies exactly what has happened with the patient's care at your practice and posts the charges for the code.  Learn to complete your planned codes.",
    type: "generic",
    category: "Complete Treatment",
    thumbnail: Chart,
  },
  {
    id: 14,
    title: "How to complete and save a note",
    link: "https://vimeo.com/653469550",
    description:
      "Codes don't provide all the information about a patient's treatment.  You often need more information in the form of a note.  Learn how to use CareNotes to include those details when they are fresh in your mind.",
    type: "generic",
    category: "CareStack Care Notes",
    thumbnail: Note,
  },
  {
    id: 15,
    title: "How to prescribe a medication",
    link: "https://vimeo.com/459549951",
    description:
      "Your patient may need antibiotics, pain medication, or special oral solutions.  Learn to prescribe what they need with saved details and a printable option.",
    type: "generic",
    category: "Prescriptions",
    thumbnail: Prescription,
  },
  {
    id: 16,
    title: "How to chart pocket depth",
    link: "https://vimeo.com/656241056",
    description:
      "CareStack has a detailed separate module to describe the patient's periodiodontal health.  Learn to enter the pocket depth measurements to buid the record.",
    type: "generic",
    category: "Perio Charting",
    thumbnail: Perio,
  },
  {
    id: 17,
    title: "How to create a treatment plan",
    link: "https://vimeo.com/634660950",
    description:
      "Patients want to see how the treatments you recommend come together into a comprehesive strategy for treatment.  Learn to structure those planned treatments into a plan that can be presented to the patient.",
    type: "generic",
    category: "Treatment Planning",
    thumbnail: TXPlan,
  },
  {
    id: 18,
    title: "How to add phases for a treatment plan",
    link: "https://vimeo.com/634663834",
    description:
      "Treatment plans can be long and elaborate.  It helps both you and the patient when you organize them into bite-sized chunks.  Learn to add phases into your treatment plan.",
    type: "generic",
    category: "Treatment Planning",
    thumbnail: TXPlan,
  },
  {
    id: 19,
    title: "How to deliver the case",
    link: "https://vimeo.com/767081884",
    description:
      "Once you have the treatment plan built, it's time to show the patient.  Learn to show what you want how you want and get the patient's buy-in.",
    type: "generic",
    category: "",
    thumbnail: CaseDeliver,
  },
  {
    id: 20,
    title: "How to add a payment receipt",
    link: "https://vimeo.com/533008022",
    description:
      "CareStack's payment tracking ties payments directly to the codes instead of keeping them all together.  The payment process starts with adding the receipt.  Learn to add the patient's receipt and include the critical details.",
    type: "generic",
    category: "Payments for Patients",
    thumbnail: PmtSlider,
  },
  {
    id: 21,
    title: "How to apply a payment to codes",
    link: "https://vimeo.com/533006723",
    description:
      "Once a payment is added, it must be tied to the code or codes it is paying for.  Learn to apply a payment to the codes.",
    type: "generic",
    category: "Payments for Patients",
    thumbnail: PmtSlider,
  },
  {
    id: 22,
    title: "How to give the patient a summary doc",
    link: "https://vimeo.com/656715325",
    description:
      "Patients like receipts.  They like knowing what they had done and what it cost.  Learn to provide your patient with a summary of their appointment.",
    type: "generic",
    category: "Payments for Patients",
    thumbnail: SummaryDoc,
  },
  {
    id: 23,
    title: "How to create an individual claim",
    link: "https://vimeo.com/489603569",
    description:
      "Claims are the tool for collecting from your patients' insurance carriers.  Learn to create an individual claim for a patient's recent appointment.",
    type: "generic",
    category: "Claims Essentails",
    thumbnail: ClaimSliderForm,
  },
  {
    id: 24,
    title: "How to create a batch of claims",
    link: "https://vimeo.com/489604044",
    description:
      "As important as claims are, wouldn't it be easier if you could do them all at once instead of one at a time after each appointment?  Learn to guide CareStack to create your claims for you for a day at a time.",
    type: "generic",
    category: "Claims Essentails",
    thumbnail: ClaimModule,
  },
  {
    id: 25,
    title: "Transmit or Print",
    link: "https://vimeo.com/489614450",
    description:
      "You created the claims for your patients and now they need to get out the door and on their way to the clearinghouse.  Learn to electronically transmit or print those claims.",
    type: "generic",
    category: "Claims Essentails",
    thumbnail: ClaimModule,
  },
  {
    id: 26,
    title: "How to add an insurance receipt",
    link: "https://vimeo.com/463156354",
    description:
      "You completed the treatment and sent the claim.  Now the insurance carrier has sent a payment. There are several steps to get those payments associated with the appropriate claims.  Learn to add the payment reciepts so they can be applied.",
    type: "generic",
    category: "Enter Insurance Payments",
    thumbnail: InsPmtModule,
  },
  {
    id: 27,
    title: "How to apply an insurance payment that matches",
    link: "https://vimeo.com/462846432",
    description:
      "With the insurance receipt added, you'll associate the payment with the claim.  Learn how to apply the insurance funds to the claim when the payment matches your expectation.",
    type: "generic",
    category: "Apply Insurance Paments",
    thumbnail: InsPmtModule,
  },
  {
    id: 28,
    title: "How to apply an insurance payment that doesn't match (1)",
    link: "https://vimeo.com/463158669",
    description:
      "With the insurance receipt added, you'll associate the payment with the claim.  Sometimes what you get isn't what you expect.  Learn how to apply the insurance funds and include adjustments to manage the difference between what you expected and what you got.",
    type: "generic",
    category: "Apply Insurance Paments",
    thumbnail: InsPmtModule,
  },
  {
    id: 29,
    title: "How to apply an insurance payment that doesn't match (2)",
    link: "https://vimeo.com/463159282",
    description:
      "With the insurance receipt added, you'll associate the payment with the claim.  Sometimes what you get isn't what you expect.  Learn how to apply the insurance funds and include adjustments to manage the difference between what you expected and what you got.",
    type: "generic",
    category: "Apply Insurance Paments",
    thumbnail: InsPmtModule,
  },
  {
    id: 30,
    title: "How to apply a secondary insurance payment",
    link: "https://vimeo.com/863175328",
    description:
      "A second layer of insruance claims and payments requires a bit more finesse as you manage what each carrier paid.  Learn to apply secondary insurance payments after the primary has paid.",
    type: "generic",
    category: "Secondary Insurance",
    thumbnail: InsPmtModule,
  },
  {
    id: 31,
    title: "#1 Dental Insurance Process Overview",
    link: "https://vimeo.com/812706152",
    description:
      "Understanding the dental insurance process in one go can be time-consuming and intimidating for practices. It is important to learn the basics of dental insurance, how the coverage works for preventive, basic and major services and many more to run a practice successfully.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp1,
    date: "Mar 16 2022",
  },
  {
    id: 32,
    title: "#2 Set-Up for Success",
    link: "https://vimeo.com/812706891",
    description:
      "A streamlined insurance workflow is critical to establishing a reliable revenue stream for the practice. Join us for the Bootcamp and understand how CareStack can help you set up your insurance workflow for success.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp2,
    date: "Mar 23 2022",
  },
  {
    id: 33,
    title: "#3 Insurance Claims & Payment Posting",
    link: "https://vimeo.com/812707412",
    description:
      "Insurance claims and payment posting entails many steps and might seem complex at times. But it can be a quick and simple task when CareStack is involved. Join the webinar to learn how to: 1. Simplify dental insurance claims from start to finish. 2. Post payments with CareStack.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp3,
    date: "Mar 30 2022",
  },
  {
    id: 34,
    title: "#4 Everything Ledger",
    link: "https://vimeo.com/812707553",
    description:
      "It's high time to end the recurring manual tasks at your practice. With CareStack you can easily record treatments and procedures, track ongoing patient data, and manage payments�all from one place. Join us for a walkthrough to learn more.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp4,
    date: "Apr 13 2022",
  },
  {
    id: 35,
    title: "#5 Transfer Credits",
    link: "https://vimeo.com/812707714",
    description:
      "Properly managing insurance transfer credits can help you accelerate your revenue cycle. In this webinar, learn how CareStack makes it easy to transfer insurance credits, ensuring financial growth.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp5,
    date: "Apr 20 2022",
  },
  {
    id: 36,
    title: "#6 RCM-Focused Reporting with Billie DiMarco",
    link: "https://vimeo.com/812707872",
    description:
      "Where there's data, there's usually untapped information waiting to be discovered and leveraged. Join us for the upcoming Bootcamp to identify the key drivers of your business to ensure a healthy RCM system. Takeaways:1. Gathering the right set of data 2. Best CareStack reports to manage your AR",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp6,
    date: "Apr 27 2022",
  },
  {
    id: 37,
    title: "#7 Patient Engagement",
    link: "https://vimeo.com/812708013",
    description:
    "Keep your waiting rooms busy, chairs full, and effectively drive patients in! Join us for the upcoming Bootcamp to learn about all things patient engagement - where we show you ways to keep your schedules full, keep your patients informed, and how to make CareStack work for you.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp7,
    date: "May 4 2022",
  },
  {
    id: 38,
    title: "#8 Reporting 2.0 with Wendy Thompson",
    link: "https://vimeo.com/812708809",
    description:
    "You asked for more! Wendy Thompson, a previous guest on Dinner with the Practice Heroes, is joining us again to show us more about dental reporting - but this time, it's all about CareStack-specific reports! Takeaways: 1. Understanding CareStack reports 2. Gathering the right information",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp8,
    date: "May 11 2022",
  },
  {
    id: 39,
    title: "#9 Ledger 2.0",
    link: "https://vimeo.com/812709305",
    description:
    "Keep track of patient payments, quickly and easily find exact transactions, and dig deeper into the component details with the CareStack ledger. Join us for the next Bootcamp to learn how you can better manage your finances through easy tracking. Takeaways: 1. Interactive ledger for account summary, transaction record, and balance overview 2. Ledger features - filter and sort your transactions, claim entries, etc",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp9,
    date: "May 18 2022",
  },
  {
    id: 40,
    title: "#10 Clinical Notes",
    link: "https://vimeo.com/812716944",
    description:
    "Set your team up for success with effortless notes. Construct smart logic templates and complete your procedures with ease. Join us for the event where we show you different ways to build and fill without a hitch! Takeaways: 1. Build efficient notes with prompts, responses, and templates 2. Edit list items and templates for well-organized notes",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp10,
    date: "May 25 2022",
  },
  {
    id: 41,
    title: "#11 Ledger 3.0",
    link: "https://vimeo.com/812717224",
    description:
    "Due to popular demand, we're excited to see you on CareStack Bootcamp for another night all about the Ledger! Takeaways: 1. Additional ledger tips and tricks 2. Care Credit Transaction Fees set-up",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp11,
    date: "Jun 8 2022",
  },
  {
    id: 42,
    title: "#12 Ledger 4.0",
    link: "https://vimeo.com/812718653",
    description:
    "Just when you think you have dental insurance all figured out, it starts to get more complicated with secondary insurance. Join us for the next episode to learn everything about the insurance chain but from the perspective of secondary insurance. Takeaways: 1. Secondary Insurance Estimates 2. Secondary Insurance Payments 3. Reversing Adjustments/Discounts",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp12,
    date: "Jul 6 2022",
  },
  {
    id: 43,
    title: "#13 Patient Facing tech & tools",
    link: "https://vimeo.com/812733745",
    description:
    "CareStack's technology goes beyond just being an organizational tool. Join us for our next Bootcamp to explore tools like Patient Kiosk, Patient Portal, and Online Appointment Booking and make every interaction between the patient and your practice easier than ever. Takeaways: 1. Tips & Tricks - [NEW] Patient Kiosk and Patient Portal 2. Reducing no-shows with online appointment booking",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp13,
    date: "Jul 13 2022",
  },
  {
    id: 44,
    title: "#14 CS Onboarding for New Hires",
    link: "https://vimeo.com/812742162",
    description:
    "Effective, new employee onboarding involves more than just orientation. Join the webinar and learn the best practices for onboarding new employees into CareStack, and re-training existing employees to ensure productivity, employee satisfaction, and retention.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp14,
    date: "Jul 20 2022",
  },
  {
    id: 45,
    title: "#15 Transfers 2.0",
    link: "https://vimeo.com/812743739",
    description:
    "Get a deep understanding of your transfer credits, unapplied insurance adjustments, and how you can fix them. Demystify your numbers with CareStack to know where your collections are held and how easily you can correct transfers.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp15,
    date: "Aug 17 2022",
  },
  {
    id: 46,
    title: "#16 Patient Engagement 2.0",
    link: "https://vimeo.com/812745643",
    description:
    "There are multiple ways to engage with your patients once they are beyond the walls of dental practice. In our next episode, learn how you can make it easy for your patients to book an appointment with online booking and keep them on the loop with campaigns!",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp16,
    date: "Aug 31 2022",
  },
  {
    id: 47,
    title: "#17 How to be a ROCKSTAR at Closing Treatment Plans",
    link: "https://vimeo.com/812751478",
    description:
    "Getting your patients to say YES might be one of the hardest day to day job in a dental practice. However, combining patient education along with a little bit of sales trick might just be what you need to close a case. Join the next bootcamp series to find out!",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp17,
    date: "Sep 14 2022",
  },
  {
    id: 48,
    title: "#18 Closing of Treatment Plans",
    link: "https://vimeo.com/812752429",
    description:
    "Now that you have learned how to plan out your treatment plans efficiently, the next step is to learn how to 'close the deal'. Join our next Bootcamp to find out how!",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp18,
    date: "Oct 12 2022",
  },
  {
    id: 49,
    title: "#19 Minimize the Risk of Cancellations & No-Shows!",
    link: "https://vimeo.com/812753630",
    description:
    "Cancellations and no-shows cost practices tens of thousands of dollars in lost revenue every year. Even though cancellations cannot be avoided entirely, join us for the bootcamp and learn how to convert no-show time slots into valuable production time!",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp19,
    date: "Oct 26 2022",
  },
  {
    id: 50,
    title: "#21 Year-End Planning & Preparations for your Practice",
    link: "https://vimeo.com/814137716",
    description:
    "It's getting to that busy time of year again. Time to block your holidays and start thinking about the production goals, discuss financial and non-financial goals, and more. Get prepared to welcome the new year with gratitude and goals!",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp21,
    date: "Nov 16 2022",
  },
  {
    id: 51,
    title: "#22 New Year Planning & Preparations to Grow Your Practice in 2023",
    link: "https://vimeo.com/814139668",
    description:
    "New year, new goals! But how do you set them up? What trends should you start implementing, and where should you invest your time and resources? Get all the answers to those troubling questions in our Bootcamp!",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp22,
    date: "Dec 7 2022",
  },
  {
    id: 52,
    title: "#23 Pro Tips - The Clinical Catch!",
    link: "https://vimeo.com/814140361",
    description:
    "Set your team up for success with effortless notes. Construct smart logic templates and stay on top of your clinical notes for prompt reimbursement. Join us for the event, where we show you different ways to build and fill without a hitch!",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp23,
    date: "Jan 18 2023",
  },
  {
    id: 53,
    title: "#24 Which reports can give me what I need?",
    link: "https://vimeo.com/814141803",
    description:
    "Do you find yourself lost or overwhelmed with reporting choices... or maybe you have no idea where to start? Have you asked yourself, Which report can give me what I need? How do I use all these filters? This Bootcamp episode will help you find answers to all your questions.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp24,
    date: "Mar 22 2023",
  },
  {
    id: 54,
    title: "#25 Reporting, continued!",
    link: "https://vimeo.com/821652553",
    description:
    "With so much to cover on our March Bootcamp webinar, we decided we're revisiting Reporting again! We'll dive into Weekly & Monthly Reporting best practices, and check out other analytic resources too!",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp25,
    date: "Apr 26 2023",
  },
  {
    id: 55,
    title: "#26 Stack me up for Success! Part 1: Production Calendars & Online Booking",
    link: "https://vimeo.com/842902777",
    description:
    "Are you struggling to stay on top of your production schedule and book appointments efficiently? Look no further! Join us for the webinar series, where we'll explore how to use production calendars and online booking tools to take your practice productivity to the next level.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp26,
    date: "May 31 2023",
  },
  {
    id: 56,
    title: "#27: Stack me up for Success! Part 2: CS Conversations",
    link: "https://vimeo.com/842440224",
    description:
    "Say goodbye to missed leads and hello to skyrocketing new patient numbers. Introducing CS Conversations - the ultimate solution to increase new patient conversion and drive exponential growth for your practice. With our cutting-edge AI technology, you can now analyze phone calls, identify missed opportunities, and re-engage with prospects in real-time.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp27,
    date: "Jun 28 2023",
  },
  {
    id: 57,
    title: "#28: Stack me up for Success! Part 3: CS Memberships",
    link: "https://vimeo.com/850458247",
    description:
    "Grow your Patient Base and Increase Case Acceptance. Do you have an in-house membership program, and are you feeling weighed down, spending a lot of time managing your plans? Our Membership Plan platform allows you to create and manage plans and patients, accurately allocate provider income, and integrate cards-on-file for seamless collections.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp28,
    date: "Jul 26 2023",
  },
  {
    id: 58,
    title: "#29: Best Practices for Managing Claims with CareStack",
    link: "https://vimeo.com/876349851",
    description:
    "Avoiding incomplete or inaccurate claims can ensure a high rate of claims acceptance, eventually reducing your claims aging and resulting in faster dental claims reimbursements. The CareStack PMS has various features built into it that facilitate a practice to effectively and efficiently process claims, enabling a healthy cash flow for your practice.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp29,
    date: "Oct 19 2023",
    
  },
  {
    id: 59,
    title: "5.33 Feature Release Webinar",
    link: "https://vimeo.com/886343077",
    description:
    "Unveiling exciting enhancements to your CareStack experience!",
    type: "webinar",
    category: "Webinar",
    thumbnail: Webinar533,
    date: "Nov 15 2023",
    
  },
  {
    id: 60,
    title: "#30: Maximizing Dental Practice Success: Year-End Strategies and New Year Growth",
    link: "https://vimeo.com/894874307",
    description:
    "Analyze annual data to uncover growth and improvement opportunities, how to set realistic yet ambitious goals, providing a clear and achievable roadmap, master techniques to boost reviews without extra marketing spend.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp30,
    date: "Dec 06 2023",
    
  },
  {
    id: 61,
    title: "#31: All About the New Ledger",
    link: "https://vimeo.com/910528595",
    description:
    "Join us in exploring the new Ledger and learning how you can use its newest features to improve your daily workflows. Also, learn all about the new Operational Report view and the 'Favorites' option.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp31,
    date: "Feb 01 2024",
    
  },
  {
    id: 62,
    title: "#32: Operational Reports, New Ledger & More! - Part 2",
    link: "https://vimeo.com/914416708",
    description:
    "Welcome back! We're thrilled to dive deeper with you in the second part of our Ledger webinar series. This time, we are going to dive deeper into the details, new features, workflows, and improvements of the ledger. Our aim is to answer your lingering questions and provide insights into how these updates can streamline your daily operations.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp32,
    date: "Feb 15 2024",
    
  },
  {
    id: 63,
    title: "#33: New Ledger - Top 10 Questions",
    link: "https://vimeo.com/919222533",
    description:
    "New Ledger is in high demand for Bootcamp! This interactive session is your opportunity to delve into the top 10 discussions directly with the experts, as we break down each point live. But that's not all—we're eager to hear from you!",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp33,
    date: "Feb 28 2024",
    
  },
  {
    id: 64,
    title: "#34: Operational Reporting Efficiencies & More!",
    link: "https://vimeo.com/929760036",
    description:
    "Join us for an in-depth review of our new Operational Reports layout and features. See how you can improve your daily operations and workflow with all things reporting.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp34,
    date: "Mar 27 2024",
    
  },
  {
    id: 65,
    title: "5.35 Feature Release Webinar",
    link: "https://vimeo.com/939044009",
    description:
    "Unveiling exciting enhancements to your CareStack experience!",
    type: "webinar",
    category: "Webinar",
    thumbnail: Webinar535,
    date: "Apr 24 2024",
    
  },
  {
    id: 66,
    title: "#35: Unlocking Profit Potential Episode 1: Powering Growth with Strategic Scheduling",
    link: "https://vimeo.com/943778283",
    description:
    "Powering Growth with Strategic Scheduling.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp35,
    date: "May 15 2024",
    
  },
  {
    id: 67,
    title: "#36: Putting Systems to Work - Powering Growth with Strategic Scheduling, Part 2",
    link: "https://vimeo.com/952206586",
    description:
    "Join us for our latest webinar and learn advanced strategies to transform your practice. Discover how to leverage scheduling tools and templating techniques to enhance workflow and boost case acceptance.",
    type: "webinar",
    category: "Bootcamp",
    thumbnail: Bootcamp36,
    date: "Jun 05 2024",
    
  },
  {
    id: 68,
    title: "5.36 Feature Release Webinar",
    link: "https://vimeo.com/989358554",
    description:
    "We are excited to announce the release of CareStack 5.36. This update brings a host of new functionalities across various domains, ensuring a seamless experience for all users.",
    type: "webinar",
    category: "Webinar",
    thumbnail: Webinar536,
    date: "Jul 17 2024",
    
  },
  {
    id: 69,
    title: "Introducing CareFlow",
    link: "https://vimeo.com/990043963",
    description:
    "We are thrilled to invite you to our exclusive webinar that unveils the full potential of CareFlow. Join us to discover how CareFlow can enhance your practice with simplified workflows, increased efficiency, and a superior user experience.",
    type: "webinar",
    category: "Webinar",
    thumbnail: CareFlow,
    date: "Jul 24 2024",
  },
  {
      id: 70,
      title: "Implant Tracker Guide",
      link: "https://vimeo.com/957090127",
      description: "Check out this video to learn how to configure and manage the implant tracker, maximizing its benefits for both patients and the practice.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 71,
      title: "Overview of Care Notes",
      link: "https://vimeo.com/973081481",
      description: "Watch this video to learn how to use the latest Care Notes features to simplify your dental charting.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 72,
      title: "Odontogram and Grid Actions for Care Notes",
      link: "https://vimeo.com/973081063",
      description: "Make the most of this video walkthrough to learn how to add an addendum from the Odontogram and check its status. You'll also discover how to effectively access and manage your Care Notes.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 73,
      title: "Care Notes Location Restrictions, Print Settings, and Duplicating Care Notes",
      link: "https://vimeo.com/973081335",
      description: "Check out this video to explore the new robust features of Care Notes, including location restrictions, enhanced print functionality, and the ability to duplicate care notes.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 74,
      title: "Smart Phrases Templates & Settings",
      link: "https://vimeo.com/973081587",
      description: "Watch this video to find out how to make the most of the Smart Phrases option and learn how to configure them effortlessly.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 75,
      title: "Add Linked Templates & Addendum",
      link: "https://vimeo.com/973081655",
      description: "Watch this video for a quick overview of the enhanced Care Notes Merge functionality. You'll learn how to view linked templates, add an Addendum, use the open-in-chart icon to view the editor and odontogram simultaneously, and more.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 76,
      title: "Care Stack Note Editor Enhancements",
      link: "https://vimeo.com/973081218",
      description: "Watch this video to learn how to effortlessly create care notes while viewing the dental chart with the enhanced care note editor. You'll also discover how to easily add responses.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 77,
      title: "Charging UCR on Non Covered Codes in Insurance Plans ",
      link: "https://vimeo.com/954283396",
      description: "Dive into this detailed walkthrough to learn everything about how the UCR fee is applied to all non-covered codes.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 78,
      title: "Capitation Payments",
      link: "https://vimeo.com/948475347",
      description: "Learn to add and manage Capitation Payments in CareStack!",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 79,
      title: "Electronic Remittance - ERA",
      link: "https://vimeo.com/948476360",
      description: "Watch this video to learn how your practice can manage ERAs effectively within CareStack.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 80,
      title: "Electronic Remittance - Pre-Auth",
      link: "https://vimeo.com/948476421",
      description: "With Electronic Remittances, practices can automatically gather insurance pre-authorizations directly into CareStack. Watch this video to discover how to set up your practice settings for effective management of electronic remittances for Pre-Auths.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 81,
      title: "Pre-Authorization Grid",
      link: "https://vimeo.com/949076730",
      description: "Watch this video to learn how to create, manage, and initiate the Pre-Authorization process within CareStack.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 82,
      title: "Ortho Schedules",
      link: "https://vimeo.com/948476807",
      description: "Learn how to manage ortho schedules, monitor their statuses, and take appropriate actions as needed.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 83,
      title: "Ortho Control Centre",
      link: "https://vimeo.com/948476998",
      description: "Watch this video for a thorough overview of the centralized ortho control center. You'll also learn how to view orthodontic statuses, track days until the next appointment, generate necessary codes, and more.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 84,
      title: "Batch Statements",
      link: "https://vimeo.com/954309786",
      description: "Learn how to access batch statements, send them via print or email, track their status and history, and manage related information directly from the grid.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 85,
      title: "Completed Procedures Grid",
      link: "https://vimeo.com/954309786",
      description: "Check out this video to explore the completed procedures grid in CareStack. Learn how to filter through various information and perform different actions as needed.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 86,
      title: "Add & Manage Collection Payments",
      link: "https://vimeo.com/948475776",
      description: "Watch this video to learn how to post and manage payments from a collection agency for a patient.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 87,
      title: "Payment Transactions List",
      link: "https://vimeo.com/948475970",
      description: "Discover how to access the Payment Transactions List in CareStack to monitor details related to your integrated merchant processor and manage receipts for refunded transactions.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 88,
      title: "Sunbit Third Party Financing Integration",
      link: "https://vimeo.com/989706115",
      description: "Sunbit is a vital integration that simplifies checking eligibility, initiating checkouts, and automatically creating receipts after transactions. Check out this video to learn how to effortlessly integrate Sunbit into your PMS.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 89,
      title: "Unapplied Credit Management Tour",
      link: "https://vimeo.com/962229141",
      description: "The Unapplied Credit Management feature is a major upgrade designed to help you manage unapplied credits more efficiently. Watch this video to learn more.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 90,
      title: "System Defined Filters for Unapplied Credit Management",
      link: "https://vimeo.com/962222617",
      description: "Watch this video to learn how to effectively manage the Unapplied Credit Management module to best meet your practice's needs.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  },
  {
      id: 91,
      title: "Notifications Feature",
      link: "https://vimeo.com/999012624",
      description: "Watch this video for an in-depth tutorial on how to effectively use the notifications feature to your benefit.",
      type: "generic",
      category: "CareFlow",
      date: "Aug 18 2024",
      careflow: true
  }
];
